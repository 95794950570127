var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CommentMgrForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          { ref: "formPanel", attrs: { submitBefore: _vm.submitBefore } },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "infoTop" }, [
              _c("div", { staticClass: "topLeft" }, [
                _c("img", { attrs: { src: _vm.topic.userHeadPic, alt: "" } }),
              ]),
              _c("div", { staticClass: "topRight" }, [
                _c("span", [
                  _c("b", [_vm._v("发布人")]),
                  _vm._v("：" + _vm._s(_vm.topic.userName)),
                ]),
                _c("br"),
                _c("span", [
                  _c("b", [_vm._v("发布时间")]),
                  _vm._v("：" + _vm._s(_vm.topic.intime)),
                ]),
                _c("br"),
                _vm.topic.entime
                  ? _c("span", [
                      _c("b", [_vm._v("结束时间")]),
                      _vm._v("：" + _vm._s(_vm.topic.entime)),
                    ])
                  : _vm._e(),
                _c("br"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "tools" },
              [
                _c("v-button", {
                  attrs: {
                    type: "text",
                    text: _vm.topic.isTop === 0 ? "置顶" : "取消置顶",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.articleTop()
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { type: "text", text: "回复" },
                  on: {
                    click: function ($event) {
                      return _vm.articleReply()
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "infoCenter" }, [
              _vm.topic.topicTitle
                ? _c("span", [
                    _c("b", [_vm._v("主题")]),
                    _vm._v("：" + _vm._s(_vm.topic.topicTitle) + "  "),
                  ])
                : _vm._e(),
              _vm.topic.topicTypeName
                ? _c("span", [
                    _c("b", [_vm._v("类型")]),
                    _vm._v("：" + _vm._s(_vm.topic.topicTypeName) + "  "),
                  ])
                : _vm._e(),
              _vm.topic.topicContent
                ? _c("span", [
                    _c("b", [_vm._v("简介")]),
                    _vm._v("：" + _vm._s(_vm.topic.topicContent) + "  "),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("div", [_c("b", [_vm._v("内容")]), _vm._v(":\n        ")]),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.topic.body) } }),
            ]),
            _c(
              "div",
              { staticClass: "infoBottom" },
              _vm._l(_vm.msglist, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "bottomContent",
                    class: { active: index % 2 !== 0 },
                  },
                  [
                    _c("span", [_c("b", [_vm._v(_vm._s(item.userName))])]),
                    _vm._v(":    \n          "),
                    _c("span", [
                      _vm._v(
                        "回复@" +
                          _vm._s(item.toUserName) +
                          ":  " +
                          _vm._s(item.msgContent)
                      ),
                    ]),
                    _c("br"),
                    _c("div", { staticClass: "opsButton" }, [
                      _c("div", [_vm._v(_vm._s(item.intime))]),
                      _c(
                        "div",
                        [
                          _c("v-button", {
                            attrs: {
                              type: "text",
                              text: item.isTop === 0 ? "置顶" : "取消置顶",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.top(item)
                              },
                            },
                          }),
                          _vm._v(" \n              "),
                          _c("v-button", {
                            attrs: { type: "text", text: "回复" },
                            on: {
                              click: function ($event) {
                                return _vm.reply(item)
                              },
                            },
                          }),
                          _vm._v(" \n              "),
                          _c("v-button", {
                            attrs: { type: "text", text: "删除" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteInfo(item)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: _vm.title,
                visible: _vm.operaDialog,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.operaDialog = $event
                },
                close: _vm.closeDialog,
              },
            },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-width": "140px",
                    "label-suffix": "：",
                    size: "medium",
                  },
                },
                [
                  _c("el-row"),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("v-textarea", {
                            attrs: { maxlength: 200 },
                            model: {
                              value: _vm.msgContent,
                              callback: function ($$v) {
                                _vm.msgContent = $$v
                              },
                              expression: "msgContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.operaDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitOpera },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }