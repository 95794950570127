<template>
  <div class="CommentMgrForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
    >
      <div class="info">
        <div class="infoTop">
          <div class="topLeft">
            <img :src="topic.userHeadPic" alt="">
          </div>
          <div class="topRight">
            <span><b>发布人</b>：{{topic.userName}}</span><br/>
            <span><b>发布时间</b>：{{topic.intime}}</span><br/>
            <span v-if="topic.entime"><b>结束时间</b>：{{topic.entime}}</span><br/>
          </div>
        </div>
        <div class="tools">
          <v-button type="text" :text="topic.isTop === 0 ? '置顶' : '取消置顶'" @click="articleTop()"></v-button>
          <v-button type="text" text="回复" @click="articleReply()"></v-button>
        </div>
        <div class="infoCenter">
          <span v-if="topic.topicTitle"><b>主题</b>：{{topic.topicTitle}}&nbsp;&nbsp;</span>
          <span v-if="topic.topicTypeName"><b>类型</b>：{{topic.topicTypeName}}&nbsp;&nbsp;</span>
          <span v-if="topic.topicContent"><b>简介</b>：{{topic.topicContent}}&nbsp;&nbsp;</span>
        </div>
        <div class="content">
          <div>
            <b>内容</b>:
          </div>
          <div v-html="topic.body"></div>
        </div>
        <div class="infoBottom">
          <div v-for="(item, index) in msglist" :key="index" class="bottomContent" :class="{ 'active': index%2 !== 0 }">
            <span><b>{{item.userName}}</b></span>:&nbsp;&nbsp;&nbsp;&nbsp;
            <span>回复@{{item.toUserName}}:&nbsp;&nbsp;{{item.msgContent}}</span><br/>
            <div class="opsButton">
              <div>{{item.intime}}</div>
              <div>
                <v-button type="text" :text="item.isTop === 0 ? '置顶' : '取消置顶'" @click="top(item)"></v-button>&nbsp;
                <v-button type="text" text="回复" @click="reply(item)"></v-button>&nbsp;
                <v-button type="text" text="删除" @click="deleteInfo(item)"></v-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
      :title="title"
      :visible.sync="operaDialog"
      width="700px"
      class="dialog"
      @close="closeDialog">
      <el-form label-width="140px" label-suffix="：" size="medium">
      <el-row>
      </el-row>
      <el-row>
        <el-col :span="12">
          <v-textarea v-model="msgContent" :maxlength="200"></v-textarea>
        </el-col>
      </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operaDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitOpera">确 定</el-button>
      </span>
    </el-dialog>
    </form-panel>
  </div>
</template>
<script>
import { detailURL, replyURL, deleteURL, commentTop, replyUrlNew, articleTop, commentList } from './api'
import qs from 'qs'
export default {
  name: 'InterActionCheck',
  data () {
    return {
      width: 182,
      title: '',
      topic: '',
      loginUser: '',
      operaDialog: false, 
      id: '',
      msgContent: '',
      msglist: [],  
      submitConfig: {
        queryUrl: detailURL,
        submitUrl: '',
        queryMethod: 'post'
      },
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      },
      isTopic: false
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('帖子详情')
      this.submitConfig.submitUrl = ''
      this.getData()
    }
    // this.handleGetCommentList()
  },
  methods: {
    async deleteInfo (item) {
      let isOk = await this.$confirm('确认删除？')
      isOk && this.delete(item)
    },
    delete (data) {
      let _this = this
      let postData = {
        id: data.id
      }
      _this.$axios.post(deleteURL, postData, this.postHeader).then(res => {
        if (res.status === '100') {
          _this.getData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    reply (data) {
      this.title = `回复@` + `${data.toUserName}`
      this.operaDialog = true
      this.id = data.id
      this.isTopic = false
    },
    // 置顶
    async top (data) {
      let isOk = await this.$confirm('确认置顶该评论？')
      isOk && this.handleCommentTop(data)
    },
    submitOpera () {
      if (!this.isTopic) {
        let data = {
          title: '回复',
          id: this.id,
          msgContent: this.msgContent
        }
        this.$axios.post(replyURL, data, this.postHeader).then(res => {
          if (res.status === "100") {
            this.operaDialog = false
            this.getData()
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
      } else {
        // 回复帖子
        const { neighborId } = this.$route.query
        const params = {
          type: 1,
          message: this.msgContent,
          neighborId: neighborId,
          messageId: ''
        }
        this.$axios.post(replyUrlNew, qs.stringify(params)).then(res => {
          if (res.status === 100) {
            this.operaDialog = false
            this.getData()
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
      }
    },
    closeDialog () {
      this.operaDialog = false
    },
    getData () {
      const { id, neighborId } = this.$route.query
      let data = {
        id: id,
        neighbor_id: neighborId
      }
      this.$axios.post(detailURL, data).then(res => {
        if (res.status === '100') {
          let data = res.data
          this.topic = data.topic
          let msglist = data.msglist
          let arr = []
          msglist.map(item => {
            if (item.isTop === null) {
              item.isTop = 0
            }
            arr.push(item)
          })
          this.msglist = arr
          this.loginUser = data.loginUser
        }
      })
    },
    submitBefore (data) {
      return true
    },
    // 帖子置顶
    async articleTop () {
      let isOk = await this.$confirm(`确认${this.topic.isTop === 1 ? '取消置顶' : '置顶'}该帖子？`)
      isOk && this.handleArticleTop()
    },
    // 帖子评论
    articleReply () {
      this.title = '回复'
      this.operaDialog = true
      this.id = this.topic.id
      this.isTopic = true
    },
    async handleArticleTop () {
      const { id } = this.$route.query
      let _this = this
      this.$axios({
        method: 'POST',
        url: articleTop,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: {
          id: id,
          isTop: _this.topic.isTop === 0 ? 1 : 0
        }
      }).then(res => {
        if (res.status === '100') {
          _this.getData()
          _this.$message.success('操作成功')
        }
      })
    },
    // handleGetCommentList () {
    //   const { inuserId, neighborId } = this.$route.query
    //   const params = {
    //     curPage: 1,
    //     neighborId: neighborId,
    //     inuserId: inuserId,
    //     pageSize: 100
    //   }
    //   this.$axios.get(commentList, { params })
    // }
    // 评论置顶
    async handleCommentTop (item) {
      const isType = item.isTop === 0 ? 1 : 0
      const params = {
        id: item.id,
        isType
      }
      const res = await this.$axios.post(commentTop, qs.stringify(params))
      if (res.status === 100) {
        this.$message.success('操作成功')
        this.getData()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.info {
  margin-left: 40px;
  max-width: 320px;
  .infoTop {
    height: 77px;
    background: #f9f9f9;
    display: flex;
    .topLeft {
      width: 70px;
      height: 77px;
      padding: 8px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .topRight {
      padding: 16px 0px;
    }
  }
  .tools {
    background: #f9f9f9;
    display: flex;
    padding: 0 100px;
    justify-content: space-between;
  }
  .infoCenter {
    min-height: 21px;
    padding: 8px;
    background: #f9f9f9;
  }
  .content {
    min-height: 21px;
    padding: 8px;
    background: #f9f9f9;
    ::v-deep img {
      width: 100%;
    }
    border-bottom: 2px solid #0088cc;
  }
  .infoBottom {
    .bottomContent {
      padding: 8px;
      .opsButton {
        display: flex;
        justify-content: space-between;
      }
    }
    .active {
      background: #f9f9f9;
    }
  }
}
</style>
